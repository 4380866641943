"use strict";
// source: pearls_v1.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();
var pearls_entity_pb = require('./pearls_entity_pb.js');
goog.object.extend(proto, pearls_entity_pb);
goog.exportSymbol('proto.jp.singcolor.GetPearlBalanceSummaryV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.GetPearlBalanceSummaryV1Response', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request', null, global);
goog.exportSymbol('proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetPearlBalanceSummaryV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetPearlBalanceSummaryV1Request.displayName = 'proto.jp.singcolor.GetPearlBalanceSummaryV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.GetPearlBalanceSummaryV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.GetPearlBalanceSummaryV1Response.displayName = 'proto.jp.singcolor.GetPearlBalanceSummaryV1Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.displayName = 'proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.repeatedFields_, null);
};
goog.inherits(proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.displayName = 'proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response';
}
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetPearlBalanceSummaryV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetPearlBalanceSummaryV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetPearlBalanceSummaryV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetPearlBalanceSummaryV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {};
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetPearlBalanceSummaryV1Request}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetPearlBalanceSummaryV1Request;
    return proto.jp.singcolor.GetPearlBalanceSummaryV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetPearlBalanceSummaryV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetPearlBalanceSummaryV1Request}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetPearlBalanceSummaryV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetPearlBalanceSummaryV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.GetPearlBalanceSummaryV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.GetPearlBalanceSummaryV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            pearlAmountTotal: (f = msg.getPearlAmountTotal()) && pearls_entity_pb.PearlAmount.toObject(includeInstance, f),
            pearlAmountExpiringEndOfMonth: (f = msg.getPearlAmountExpiringEndOfMonth()) && pearls_entity_pb.PearlAmount.toObject(includeInstance, f)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.GetPearlBalanceSummaryV1Response;
    return proto.jp.singcolor.GetPearlBalanceSummaryV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new pearls_entity_pb.PearlAmount;
                reader.readMessage(value, pearls_entity_pb.PearlAmount.deserializeBinaryFromReader);
                msg.setPearlAmountTotal(value);
                break;
            case 2:
                var value = new pearls_entity_pb.PearlAmount;
                reader.readMessage(value, pearls_entity_pb.PearlAmount.deserializeBinaryFromReader);
                msg.setPearlAmountExpiringEndOfMonth(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.GetPearlBalanceSummaryV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPearlAmountTotal();
    if (f != null) {
        writer.writeMessage(1, f, pearls_entity_pb.PearlAmount.serializeBinaryToWriter);
    }
    f = message.getPearlAmountExpiringEndOfMonth();
    if (f != null) {
        writer.writeMessage(2, f, pearls_entity_pb.PearlAmount.serializeBinaryToWriter);
    }
};
/**
 * optional PearlAmount pearl_amount_total = 1;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.getPearlAmountTotal = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, pearls_entity_pb.PearlAmount, 1));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response} returns this
*/
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.setPearlAmountTotal = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response} returns this
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.clearPearlAmountTotal = function () {
    return this.setPearlAmountTotal(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.hasPearlAmountTotal = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional PearlAmount pearl_amount_expiring_end_of_month = 2;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.getPearlAmountExpiringEndOfMonth = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, pearls_entity_pb.PearlAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response} returns this
*/
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.setPearlAmountExpiringEndOfMonth = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.GetPearlBalanceSummaryV1Response} returns this
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.clearPearlAmountExpiringEndOfMonth = function () {
    return this.setPearlAmountExpiringEndOfMonth(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.GetPearlBalanceSummaryV1Response.prototype.hasPearlAmountExpiringEndOfMonth = function () {
    return jspb.Message.getField(this, 2) != null;
};
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.toObject = function (includeInstance, msg) {
        var f, obj = {
            pageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            limit: jspb.Message.getFieldWithDefault(msg, 2, 0)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request;
    return proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPageToken(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readUint64());
                msg.setLimit(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPageToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getLimit();
    if (f !== 0) {
        writer.writeUint64(2, f);
    }
};
/**
 * optional string page_token = 1;
 * @return {string}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.prototype.getPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.prototype.setPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.prototype.getLimit = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};
/**
 * @param {number} value
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request} returns this
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Request.prototype.setLimit = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.repeatedFields_ = [3];
if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.toObject = function (opt_includeInstance) {
        return proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.toObject(opt_includeInstance, this);
    };
    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.toObject = function (includeInstance, msg) {
        var f, obj = {
            pearlAmountTotal: (f = msg.getPearlAmountTotal()) && pearls_entity_pb.PearlAmount.toObject(includeInstance, f),
            pearlAmountExpiringEndOfMonth: (f = msg.getPearlAmountExpiringEndOfMonth()) && pearls_entity_pb.PearlAmount.toObject(includeInstance, f),
            inOutHistoriesList: jspb.Message.toObjectList(msg.getInOutHistoriesList(), pearls_entity_pb.PearlBalanceInOutHistory.toObject, includeInstance),
            nextPageToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
            hasNext: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
        };
        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response;
    return proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new pearls_entity_pb.PearlAmount;
                reader.readMessage(value, pearls_entity_pb.PearlAmount.deserializeBinaryFromReader);
                msg.setPearlAmountTotal(value);
                break;
            case 2:
                var value = new pearls_entity_pb.PearlAmount;
                reader.readMessage(value, pearls_entity_pb.PearlAmount.deserializeBinaryFromReader);
                msg.setPearlAmountExpiringEndOfMonth(value);
                break;
            case 3:
                var value = new pearls_entity_pb.PearlBalanceInOutHistory;
                reader.readMessage(value, pearls_entity_pb.PearlBalanceInOutHistory.deserializeBinaryFromReader);
                msg.addInOutHistories(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setNextPageToken(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setHasNext(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPearlAmountTotal();
    if (f != null) {
        writer.writeMessage(1, f, pearls_entity_pb.PearlAmount.serializeBinaryToWriter);
    }
    f = message.getPearlAmountExpiringEndOfMonth();
    if (f != null) {
        writer.writeMessage(2, f, pearls_entity_pb.PearlAmount.serializeBinaryToWriter);
    }
    f = message.getInOutHistoriesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, pearls_entity_pb.PearlBalanceInOutHistory.serializeBinaryToWriter);
    }
    f = message.getNextPageToken();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
    f = message.getHasNext();
    if (f) {
        writer.writeBool(5, f);
    }
};
/**
 * optional PearlAmount pearl_amount_total = 1;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.getPearlAmountTotal = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, pearls_entity_pb.PearlAmount, 1));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.setPearlAmountTotal = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.clearPearlAmountTotal = function () {
    return this.setPearlAmountTotal(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.hasPearlAmountTotal = function () {
    return jspb.Message.getField(this, 1) != null;
};
/**
 * optional PearlAmount pearl_amount_expiring_end_of_month = 2;
 * @return {?proto.jp.singcolor.PearlAmount}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.getPearlAmountExpiringEndOfMonth = function () {
    return /** @type{?proto.jp.singcolor.PearlAmount} */ (jspb.Message.getWrapperField(this, pearls_entity_pb.PearlAmount, 2));
};
/**
 * @param {?proto.jp.singcolor.PearlAmount|undefined} value
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.setPearlAmountExpiringEndOfMonth = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.clearPearlAmountExpiringEndOfMonth = function () {
    return this.setPearlAmountExpiringEndOfMonth(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.hasPearlAmountExpiringEndOfMonth = function () {
    return jspb.Message.getField(this, 2) != null;
};
/**
 * repeated PearlBalanceInOutHistory in_out_histories = 3;
 * @return {!Array<!proto.jp.singcolor.PearlBalanceInOutHistory>}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.getInOutHistoriesList = function () {
    return /** @type{!Array<!proto.jp.singcolor.PearlBalanceInOutHistory>} */ (jspb.Message.getRepeatedWrapperField(this, pearls_entity_pb.PearlBalanceInOutHistory, 3));
};
/**
 * @param {!Array<!proto.jp.singcolor.PearlBalanceInOutHistory>} value
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} returns this
*/
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.setInOutHistoriesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.jp.singcolor.PearlBalanceInOutHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.PearlBalanceInOutHistory}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.addInOutHistories = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.jp.singcolor.PearlBalanceInOutHistory, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.clearInOutHistoriesList = function () {
    return this.setInOutHistoriesList([]);
};
/**
 * optional string next_page_token = 4;
 * @return {string}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.getNextPageToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};
/**
 * @param {string} value
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.setNextPageToken = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional bool has_next = 5;
 * @return {boolean}
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.getHasNext = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};
/**
 * @param {boolean} value
 * @return {!proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response} returns this
 */
proto.jp.singcolor.ListPearlBalanceInOutHistoriesV1Response.prototype.setHasNext = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};
goog.object.extend(exports, proto.jp.singcolor);
