/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as tsunagu_v1_pb from './tsunagu_v1_pb';
var TsunaguV1Client = /** @class */ (function () {
    function TsunaguV1Client(hostname, credentials, options) {
        this.methodDescriptorCheckAbilityToPayTsunaguByDiamondsAndPearlsV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.TsunaguV1/CheckAbilityToPayTsunaguByDiamondsAndPearlsV1', grpcWeb.MethodType.UNARY, tsunagu_v1_pb.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request, tsunagu_v1_pb.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response, function (request) {
            return request.serializeBinary();
        }, tsunagu_v1_pb.CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Response.deserializeBinary);
        this.methodDescriptorGetTransactionInfoV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.TsunaguV1/GetTransactionInfoV1', grpcWeb.MethodType.UNARY, tsunagu_v1_pb.GetTransactionInfoV1Request, tsunagu_v1_pb.GetTransactionInfoV1Response, function (request) {
            return request.serializeBinary();
        }, tsunagu_v1_pb.GetTransactionInfoV1Response.deserializeBinary);
        this.methodDescriptorExecutePaymentV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.TsunaguV1/ExecutePaymentV1', grpcWeb.MethodType.UNARY, tsunagu_v1_pb.ExecutePaymentV1Request, tsunagu_v1_pb.ExecutePaymentV1Response, function (request) {
            return request.serializeBinary();
        }, tsunagu_v1_pb.ExecutePaymentV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    TsunaguV1Client.prototype.checkAbilityToPayTsunaguByDiamondsAndPearlsV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.TsunaguV1/CheckAbilityToPayTsunaguByDiamondsAndPearlsV1', request, metadata || {}, this.methodDescriptorCheckAbilityToPayTsunaguByDiamondsAndPearlsV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.TsunaguV1/CheckAbilityToPayTsunaguByDiamondsAndPearlsV1', request, metadata || {}, this.methodDescriptorCheckAbilityToPayTsunaguByDiamondsAndPearlsV1);
    };
    TsunaguV1Client.prototype.getTransactionInfoV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.TsunaguV1/GetTransactionInfoV1', request, metadata || {}, this.methodDescriptorGetTransactionInfoV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.TsunaguV1/GetTransactionInfoV1', request, metadata || {}, this.methodDescriptorGetTransactionInfoV1);
    };
    TsunaguV1Client.prototype.executePaymentV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.TsunaguV1/ExecutePaymentV1', request, metadata || {}, this.methodDescriptorExecutePaymentV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.TsunaguV1/ExecutePaymentV1', request, metadata || {}, this.methodDescriptorExecutePaymentV1);
    };
    return TsunaguV1Client;
}());
export { TsunaguV1Client };
