/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as ope_one_phrase_events_pb from './ope_one_phrase_events_pb';
var OpeOnePhraseEventV1Client = /** @class */ (function () {
    function OpeOnePhraseEventV1Client(hostname, credentials, options) {
        this.methodDescriptorListEvents = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeOnePhraseEventV1/ListEvents', grpcWeb.MethodType.UNARY, ope_one_phrase_events_pb.OpeOnePhraseEventListEventsV1Request, ope_one_phrase_events_pb.OpeOnePhraseEventListEventsV1Response, function (request) {
            return request.serializeBinary();
        }, ope_one_phrase_events_pb.OpeOnePhraseEventListEventsV1Response.deserializeBinary);
        this.methodDescriptorListRankings = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeOnePhraseEventV1/ListRankings', grpcWeb.MethodType.UNARY, ope_one_phrase_events_pb.OpeOnePhraseEventListRankingsV1Request, ope_one_phrase_events_pb.OpeOnePhraseEventListRankingsV1Response, function (request) {
            return request.serializeBinary();
        }, ope_one_phrase_events_pb.OpeOnePhraseEventListRankingsV1Response.deserializeBinary);
        this.methodDescriptorGetEntry = new grpcWeb.MethodDescriptor('/jp.singcolor.OpeOnePhraseEventV1/GetEntry', grpcWeb.MethodType.UNARY, ope_one_phrase_events_pb.OpeOnePhraseEventGetEntryV1Request, ope_one_phrase_events_pb.OpeOnePhraseEventGetEntryV1Response, function (request) {
            return request.serializeBinary();
        }, ope_one_phrase_events_pb.OpeOnePhraseEventGetEntryV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OpeOnePhraseEventV1Client.prototype.listEvents = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeOnePhraseEventV1/ListEvents', request, metadata || {}, this.methodDescriptorListEvents, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeOnePhraseEventV1/ListEvents', request, metadata || {}, this.methodDescriptorListEvents);
    };
    OpeOnePhraseEventV1Client.prototype.listRankings = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeOnePhraseEventV1/ListRankings', request, metadata || {}, this.methodDescriptorListRankings, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeOnePhraseEventV1/ListRankings', request, metadata || {}, this.methodDescriptorListRankings);
    };
    OpeOnePhraseEventV1Client.prototype.getEntry = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OpeOnePhraseEventV1/GetEntry', request, metadata || {}, this.methodDescriptorGetEntry, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OpeOnePhraseEventV1/GetEntry', request, metadata || {}, this.methodDescriptorGetEntry);
    };
    return OpeOnePhraseEventV1Client;
}());
export { OpeOnePhraseEventV1Client };
