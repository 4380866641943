/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as lives_v1_pb from './lives_v1_pb';
var LivesV1Client = /** @class */ (function () {
    function LivesV1Client(hostname, credentials, options) {
        this.methodDescriptorGetLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetLive', grpcWeb.MethodType.UNARY, lives_v1_pb.GetLiveV1Request, lives_v1_pb.GetLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetLiveV1Response.deserializeBinary);
        this.methodDescriptorGetUserOnAirLiveV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetUserOnAirLiveV1', grpcWeb.MethodType.UNARY, lives_v1_pb.GetUserOnAirLiveV1Request, lives_v1_pb.GetUserOnAirLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetUserOnAirLiveV1Response.deserializeBinary);
        this.methodDescriptorListLives = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListLives', grpcWeb.MethodType.UNARY, lives_v1_pb.ListLivesV1Request, lives_v1_pb.ListLivesV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListLivesV1Response.deserializeBinary);
        this.methodDescriptorListTimelineInfos = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListTimelineInfos', grpcWeb.MethodType.UNARY, lives_v1_pb.ListTimelineInfosV1Request, lives_v1_pb.ListTimelineInfosV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListTimelineInfosV1Response.deserializeBinary);
        this.methodDescriptorListFollowingLives = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListFollowingLives', grpcWeb.MethodType.UNARY, lives_v1_pb.ListFollowingLivesV1Request, lives_v1_pb.ListFollowingLivesV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListFollowingLivesV1Response.deserializeBinary);
        this.methodDescriptorPrepareLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/PrepareLive', grpcWeb.MethodType.UNARY, lives_v1_pb.PrepareLiveV1Request, lives_v1_pb.PrepareLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.PrepareLiveV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserLiveRadioModeSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UpdateUserLiveRadioModeSetting', grpcWeb.MethodType.UNARY, lives_v1_pb.UpdateUserLiveRadioModeV1Request, lives_v1_pb.UpdateUserLiveRadioModeV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UpdateUserLiveRadioModeV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserLivePreEnterComment = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UpdateUserLivePreEnterComment', grpcWeb.MethodType.UNARY, lives_v1_pb.UpdateUserLivePreEnterCommentV1Request, lives_v1_pb.UpdateUserLivePreEnterCommentV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UpdateUserLivePreEnterCommentV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserLivePostEnterComment = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UpdateUserLivePostEnterComment', grpcWeb.MethodType.UNARY, lives_v1_pb.UpdateUserLivePostEnterCommentV1Request, lives_v1_pb.UpdateUserLivePostEnterCommentV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UpdateUserLivePostEnterCommentV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserLiveShareTwitterAccountSetting = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UpdateUserLiveShareTwitterAccountSetting', grpcWeb.MethodType.UNARY, lives_v1_pb.UpdateUserLiveShareTwitterAccountV1Request, lives_v1_pb.UpdateUserLiveShareTwitterAccountV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UpdateUserLiveShareTwitterAccountV1Response.deserializeBinary);
        this.methodDescriptorUpdateUserLiveClosingTime = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UpdateUserLiveClosingTime', grpcWeb.MethodType.UNARY, lives_v1_pb.UpdateUserLiveClosingTimeV1Request, lives_v1_pb.UpdateUserLiveClosingTimeV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UpdateUserLiveClosingTimeV1Response.deserializeBinary);
        this.methodDescriptorStartLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/StartLive', grpcWeb.MethodType.UNARY, lives_v1_pb.StartLiveV1Request, lives_v1_pb.StartLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.StartLiveV1Response.deserializeBinary);
        this.methodDescriptorUpdateLiveBackground = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UpdateLiveBackground', grpcWeb.MethodType.UNARY, lives_v1_pb.UpdateLiveBackgroundV1Request, lives_v1_pb.UpdateLiveBackgroundV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UpdateLiveBackgroundV1Response.deserializeBinary);
        this.methodDescriptorFinishLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/FinishLive', grpcWeb.MethodType.UNARY, lives_v1_pb.FinishLiveV1Request, lives_v1_pb.FinishLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.FinishLiveV1Response.deserializeBinary);
        this.methodDescriptorPreviewLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/PreviewLive', grpcWeb.MethodType.UNARY, lives_v1_pb.PreviewLiveV1Request, lives_v1_pb.PreviewLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.PreviewLiveV1Response.deserializeBinary);
        this.methodDescriptorEnterLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/EnterLive', grpcWeb.MethodType.UNARY, lives_v1_pb.EnterLiveV1Request, lives_v1_pb.EnterLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.EnterLiveV1Response.deserializeBinary);
        this.methodDescriptorLeaveLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/LeaveLive', grpcWeb.MethodType.UNARY, lives_v1_pb.LeaveLiveV1Request, lives_v1_pb.LeaveLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.LeaveLiveV1Response.deserializeBinary);
        this.methodDescriptorHeartbeatLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/HeartbeatLive', grpcWeb.MethodType.UNARY, lives_v1_pb.HeartbeatLiveV1Request, lives_v1_pb.HeartbeatLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.HeartbeatLiveV1Response.deserializeBinary);
        this.methodDescriptorHeartbeatLiveViewing = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/HeartbeatLiveViewing', grpcWeb.MethodType.UNARY, lives_v1_pb.HeartbeatLiveViewingV1Request, lives_v1_pb.HeartbeatLiveViewingV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.HeartbeatLiveViewingV1Response.deserializeBinary);
        this.methodDescriptorCreateLiveComment = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/CreateLiveComment', grpcWeb.MethodType.UNARY, lives_v1_pb.CreateLiveCommentV1Request, lives_v1_pb.CreateLiveCommentV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.CreateLiveCommentV1Response.deserializeBinary);
        this.methodDescriptorLikeLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/LikeLive', grpcWeb.MethodType.UNARY, lives_v1_pb.LikeLiveV1Request, lives_v1_pb.LikeLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.LikeLiveV1Response.deserializeBinary);
        this.methodDescriptorSuperLikeLive = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/SuperLikeLive', grpcWeb.MethodType.UNARY, lives_v1_pb.SuperLikeLiveV1Request, lives_v1_pb.SuperLikeLiveV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.SuperLikeLiveV1Response.deserializeBinary);
        this.methodDescriptorGetSuperLikeLiveTiming = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetSuperLikeLiveTiming', grpcWeb.MethodType.UNARY, lives_v1_pb.GetSuperLikeLiveTimingV1Request, lives_v1_pb.GetSuperLikeLiveTimingV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetSuperLikeLiveTimingV1Response.deserializeBinary);
        this.methodDescriptorListLiveViewingSessions = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListLiveViewingSessions', grpcWeb.MethodType.UNARY, lives_v1_pb.ListLiveViewingSessionsV1Request, lives_v1_pb.ListLiveViewingSessionsV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListLiveViewingSessionsV1Response.deserializeBinary);
        this.methodDescriptorListLiveHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListLiveHistories', grpcWeb.MethodType.UNARY, lives_v1_pb.ListLiveHistoriesV1Request, lives_v1_pb.ListLiveHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListLiveHistoriesV1Response.deserializeBinary);
        this.methodDescriptorStartSing = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/StartSing', grpcWeb.MethodType.UNARY, lives_v1_pb.StartSingV1Request, lives_v1_pb.StartSingV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.StartSingV1Response.deserializeBinary);
        this.methodDescriptorFinishSing = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/FinishSing', grpcWeb.MethodType.UNARY, lives_v1_pb.FinishSingV1Request, lives_v1_pb.FinishSingV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.FinishSingV1Response.deserializeBinary);
        this.methodDescriptorRequestSing = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/RequestSing', grpcWeb.MethodType.UNARY, lives_v1_pb.RequestSingV1Request, lives_v1_pb.RequestSingV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.RequestSingV1Response.deserializeBinary);
        this.methodDescriptorListGiftTabs = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListGiftTabs', grpcWeb.MethodType.UNARY, lives_v1_pb.ListGiftTabsV1Request, lives_v1_pb.ListGiftTabsV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListGiftTabsV1Response.deserializeBinary);
        this.methodDescriptorListGifts = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListGifts', grpcWeb.MethodType.UNARY, lives_v1_pb.ListGiftsV1Request, lives_v1_pb.ListGiftsV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListGiftsV1Response.deserializeBinary);
        this.methodDescriptorListGiftsForDownload = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListGiftsForDownload', grpcWeb.MethodType.UNARY, lives_v1_pb.ListGiftsForDownloadV1Request, lives_v1_pb.ListGiftsForDownloadV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListGiftsForDownloadV1Response.deserializeBinary);
        this.methodDescriptorListClosingTimes = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListClosingTimes', grpcWeb.MethodType.UNARY, lives_v1_pb.ListClosingTimesV1Request, lives_v1_pb.ListClosingTimesV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListClosingTimesV1Response.deserializeBinary);
        this.methodDescriptorListInitialClosingTimes = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListInitialClosingTimes', grpcWeb.MethodType.UNARY, lives_v1_pb.ListInitialClosingTimesV1Request, lives_v1_pb.ListInitialClosingTimesV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListInitialClosingTimesV1Response.deserializeBinary);
        this.methodDescriptorSendGift = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/SendGift', grpcWeb.MethodType.UNARY, lives_v1_pb.SendGiftV1Request, lives_v1_pb.SendGiftV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.SendGiftV1Response.deserializeBinary);
        this.methodDescriptorListFanInfoFaveBadgeTabs = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListFanInfoFaveBadgeTabs', grpcWeb.MethodType.UNARY, lives_v1_pb.ListFanInfoFaveBadgeTabsV1Request, lives_v1_pb.ListFanInfoFaveBadgeTabsV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListFanInfoFaveBadgeTabsV1Response.deserializeBinary);
        this.methodDescriptorListFanInfoSongFaveTabs = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListFanInfoSongFaveTabs', grpcWeb.MethodType.UNARY, lives_v1_pb.ListFanInfoSongFaveTabsV1Request, lives_v1_pb.ListFanInfoSongFaveTabsV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListFanInfoSongFaveTabsV1Response.deserializeBinary);
        this.methodDescriptorGetMonthlyLiveListenerInfo = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetMonthlyLiveListenerInfo', grpcWeb.MethodType.UNARY, lives_v1_pb.GetMonthlyLiveListenerInfoV1Request, lives_v1_pb.GetMonthlyLiveListenerInfoV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetMonthlyLiveListenerInfoV1Response.deserializeBinary);
        this.methodDescriptorListPfFavePointsRankingListeners = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListPfFavePointsRankingListeners', grpcWeb.MethodType.UNARY, lives_v1_pb.ListPfFavePointsRankingListenersV1Request, lives_v1_pb.ListPfFavePointsRankingListenersV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListPfFavePointsRankingListenersV1Response.deserializeBinary);
        this.methodDescriptorGetLiveResultBasicInfo = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetLiveResultBasicInfo', grpcWeb.MethodType.UNARY, lives_v1_pb.GetLiveResultBasicInfoV1Request, lives_v1_pb.GetLiveResultBasicInfoV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetLiveResultBasicInfoV1Response.deserializeBinary);
        this.methodDescriptorGetLiveResultGift = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetLiveResultGift', grpcWeb.MethodType.UNARY, lives_v1_pb.GetLiveResultGiftV1Request, lives_v1_pb.GetLiveResultGiftV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetLiveResultGiftV1Response.deserializeBinary);
        this.methodDescriptorGetUserDashboardInfo = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetUserDashboardInfo', grpcWeb.MethodType.UNARY, lives_v1_pb.GetUserDashboardInfoV1Request, lives_v1_pb.GetUserDashboardInfoV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetUserDashboardInfoV1Response.deserializeBinary);
        this.methodDescriptorShareLiveToTwitter = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ShareLiveToTwitter', grpcWeb.MethodType.UNARY, lives_v1_pb.ShareLiveToTwitterV1Request, lives_v1_pb.ShareLiveToTwitterV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ShareLiveToTwitterV1Response.deserializeBinary);
        this.methodDescriptorGetLivePermissionV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetLivePermissionV1', grpcWeb.MethodType.UNARY, lives_v1_pb.GetLivePermissionV1Request, lives_v1_pb.GetLivePermissionV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetLivePermissionV1Response.deserializeBinary);
        this.methodDescriptorGetLiveAvailableTimeRangeV1 = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetLiveAvailableTimeRangeV1', grpcWeb.MethodType.UNARY, lives_v1_pb.GetLiveAvailableTimeRangeV1Request, lives_v1_pb.GetLiveAvailableTimeRangeV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetLiveAvailableTimeRangeV1Response.deserializeBinary);
        this.methodDescriptorBanListenerComment = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/BanListenerComment', grpcWeb.MethodType.UNARY, lives_v1_pb.BanListenerCommentV1Request, lives_v1_pb.BanListenerCommentV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.BanListenerCommentV1Response.deserializeBinary);
        this.methodDescriptorUnbanListenerComment = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UnbanListenerComment', grpcWeb.MethodType.UNARY, lives_v1_pb.UnbanListenerCommentV1Request, lives_v1_pb.UnbanListenerCommentV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UnbanListenerCommentV1Response.deserializeBinary);
        this.methodDescriptorGetBannedListenerComment = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetBannedListenerComment', grpcWeb.MethodType.UNARY, lives_v1_pb.GetBannedListenerCommentV1Request, lives_v1_pb.GetBannedListenerCommentV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetBannedListenerCommentV1Response.deserializeBinary);
        this.methodDescriptorBanListenerReqCapture = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/BanListenerReqCapture', grpcWeb.MethodType.UNARY, lives_v1_pb.BanListenerReqCaptureV1Request, lives_v1_pb.BanListenerReqCaptureV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.BanListenerReqCaptureV1Response.deserializeBinary);
        this.methodDescriptorUnbanListenerReqCapture = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UnbanListenerReqCapture', grpcWeb.MethodType.UNARY, lives_v1_pb.UnbanListenerReqCaptureV1Request, lives_v1_pb.UnbanListenerReqCaptureV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UnbanListenerReqCaptureV1Response.deserializeBinary);
        this.methodDescriptorGetBannedListenerReqCapture = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetBannedListenerReqCapture', grpcWeb.MethodType.UNARY, lives_v1_pb.GetBannedListenerReqCaptureV1Request, lives_v1_pb.GetBannedListenerReqCaptureV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetBannedListenerReqCaptureV1Response.deserializeBinary);
        this.methodDescriptorGetNextFavePointsProgress = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetNextFavePointsProgress', grpcWeb.MethodType.UNARY, lives_v1_pb.GetNextFavePointsProgressV1Request, lives_v1_pb.GetNextFavePointsProgressV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetNextFavePointsProgressV1Response.deserializeBinary);
        this.methodDescriptorListLiveTimelineBanners = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListLiveTimelineBanners', grpcWeb.MethodType.UNARY, lives_v1_pb.ListLiveTimelineBannersV1Request, lives_v1_pb.ListLiveTimelineBannersV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListLiveTimelineBannersV1Response.deserializeBinary);
        this.methodDescriptorGetRankOfRankingEvent = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/GetRankOfRankingEvent', grpcWeb.MethodType.UNARY, lives_v1_pb.GetRankOfRankingEventV1Request, lives_v1_pb.GetRankOfRankingEventV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.GetRankOfRankingEventV1Response.deserializeBinary);
        this.methodDescriptorListRankingsOfRankingEvent = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/ListRankingsOfRankingEvent', grpcWeb.MethodType.UNARY, lives_v1_pb.ListRankingsOfRankingEventV1Request, lives_v1_pb.ListRankingsOfRankingEventV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.ListRankingsOfRankingEventV1Response.deserializeBinary);
        this.methodDescriptorSendHeartfulMention = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/SendHeartfulMention', grpcWeb.MethodType.UNARY, lives_v1_pb.SendHeartfulMentionV1Request, lives_v1_pb.SendHeartfulMentionV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.SendHeartfulMentionV1Response.deserializeBinary);
        this.methodDescriptorPlayYoutube = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/PlayYoutube', grpcWeb.MethodType.UNARY, lives_v1_pb.PlayYoutubeV1Request, lives_v1_pb.PlayYoutubeV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.PlayYoutubeV1Response.deserializeBinary);
        this.methodDescriptorPickUpSongRandomly = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/PickUpSongRandomly', grpcWeb.MethodType.UNARY, lives_v1_pb.PickUpSongRandomlyV1Request, lives_v1_pb.PickUpSongRandomlyV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.PickUpSongRandomlyV1Response.deserializeBinary);
        this.methodDescriptorBanListenerCommentByModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/BanListenerCommentByModerator', grpcWeb.MethodType.UNARY, lives_v1_pb.BanListenerCommentByModeratorV1Request, lives_v1_pb.BanListenerCommentByModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.BanListenerCommentByModeratorV1Response.deserializeBinary);
        this.methodDescriptorUnbanListenerCommentByModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UnbanListenerCommentByModerator', grpcWeb.MethodType.UNARY, lives_v1_pb.UnbanListenerCommentByModeratorV1Request, lives_v1_pb.UnbanListenerCommentByModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UnbanListenerCommentByModeratorV1Response.deserializeBinary);
        this.methodDescriptorBanListenerReqCaptureByModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/BanListenerReqCaptureByModerator', grpcWeb.MethodType.UNARY, lives_v1_pb.BanListenerReqCaptureByModeratorV1Request, lives_v1_pb.BanListenerReqCaptureByModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.BanListenerReqCaptureByModeratorV1Response.deserializeBinary);
        this.methodDescriptorUnbanListenerReqCaptureByModerator = new grpcWeb.MethodDescriptor('/jp.singcolor.LivesV1/UnbanListenerReqCaptureByModerator', grpcWeb.MethodType.UNARY, lives_v1_pb.UnbanListenerReqCaptureByModeratorV1Request, lives_v1_pb.UnbanListenerReqCaptureByModeratorV1Response, function (request) {
            return request.serializeBinary();
        }, lives_v1_pb.UnbanListenerReqCaptureByModeratorV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    LivesV1Client.prototype.getLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetLive', request, metadata || {}, this.methodDescriptorGetLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetLive', request, metadata || {}, this.methodDescriptorGetLive);
    };
    LivesV1Client.prototype.getUserOnAirLiveV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetUserOnAirLiveV1', request, metadata || {}, this.methodDescriptorGetUserOnAirLiveV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetUserOnAirLiveV1', request, metadata || {}, this.methodDescriptorGetUserOnAirLiveV1);
    };
    LivesV1Client.prototype.listLives = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListLives', request, metadata || {}, this.methodDescriptorListLives, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListLives', request, metadata || {}, this.methodDescriptorListLives);
    };
    LivesV1Client.prototype.listTimelineInfos = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListTimelineInfos', request, metadata || {}, this.methodDescriptorListTimelineInfos, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListTimelineInfos', request, metadata || {}, this.methodDescriptorListTimelineInfos);
    };
    LivesV1Client.prototype.listFollowingLives = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListFollowingLives', request, metadata || {}, this.methodDescriptorListFollowingLives, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListFollowingLives', request, metadata || {}, this.methodDescriptorListFollowingLives);
    };
    LivesV1Client.prototype.prepareLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/PrepareLive', request, metadata || {}, this.methodDescriptorPrepareLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/PrepareLive', request, metadata || {}, this.methodDescriptorPrepareLive);
    };
    LivesV1Client.prototype.updateUserLiveRadioModeSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UpdateUserLiveRadioModeSetting', request, metadata || {}, this.methodDescriptorUpdateUserLiveRadioModeSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UpdateUserLiveRadioModeSetting', request, metadata || {}, this.methodDescriptorUpdateUserLiveRadioModeSetting);
    };
    LivesV1Client.prototype.updateUserLivePreEnterComment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UpdateUserLivePreEnterComment', request, metadata || {}, this.methodDescriptorUpdateUserLivePreEnterComment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UpdateUserLivePreEnterComment', request, metadata || {}, this.methodDescriptorUpdateUserLivePreEnterComment);
    };
    LivesV1Client.prototype.updateUserLivePostEnterComment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UpdateUserLivePostEnterComment', request, metadata || {}, this.methodDescriptorUpdateUserLivePostEnterComment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UpdateUserLivePostEnterComment', request, metadata || {}, this.methodDescriptorUpdateUserLivePostEnterComment);
    };
    LivesV1Client.prototype.updateUserLiveShareTwitterAccountSetting = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UpdateUserLiveShareTwitterAccountSetting', request, metadata || {}, this.methodDescriptorUpdateUserLiveShareTwitterAccountSetting, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UpdateUserLiveShareTwitterAccountSetting', request, metadata || {}, this.methodDescriptorUpdateUserLiveShareTwitterAccountSetting);
    };
    LivesV1Client.prototype.updateUserLiveClosingTime = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UpdateUserLiveClosingTime', request, metadata || {}, this.methodDescriptorUpdateUserLiveClosingTime, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UpdateUserLiveClosingTime', request, metadata || {}, this.methodDescriptorUpdateUserLiveClosingTime);
    };
    LivesV1Client.prototype.startLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/StartLive', request, metadata || {}, this.methodDescriptorStartLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/StartLive', request, metadata || {}, this.methodDescriptorStartLive);
    };
    LivesV1Client.prototype.updateLiveBackground = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UpdateLiveBackground', request, metadata || {}, this.methodDescriptorUpdateLiveBackground, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UpdateLiveBackground', request, metadata || {}, this.methodDescriptorUpdateLiveBackground);
    };
    LivesV1Client.prototype.finishLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/FinishLive', request, metadata || {}, this.methodDescriptorFinishLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/FinishLive', request, metadata || {}, this.methodDescriptorFinishLive);
    };
    LivesV1Client.prototype.previewLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/PreviewLive', request, metadata || {}, this.methodDescriptorPreviewLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/PreviewLive', request, metadata || {}, this.methodDescriptorPreviewLive);
    };
    LivesV1Client.prototype.enterLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/EnterLive', request, metadata || {}, this.methodDescriptorEnterLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/EnterLive', request, metadata || {}, this.methodDescriptorEnterLive);
    };
    LivesV1Client.prototype.leaveLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/LeaveLive', request, metadata || {}, this.methodDescriptorLeaveLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/LeaveLive', request, metadata || {}, this.methodDescriptorLeaveLive);
    };
    LivesV1Client.prototype.heartbeatLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/HeartbeatLive', request, metadata || {}, this.methodDescriptorHeartbeatLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/HeartbeatLive', request, metadata || {}, this.methodDescriptorHeartbeatLive);
    };
    LivesV1Client.prototype.heartbeatLiveViewing = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/HeartbeatLiveViewing', request, metadata || {}, this.methodDescriptorHeartbeatLiveViewing, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/HeartbeatLiveViewing', request, metadata || {}, this.methodDescriptorHeartbeatLiveViewing);
    };
    LivesV1Client.prototype.createLiveComment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/CreateLiveComment', request, metadata || {}, this.methodDescriptorCreateLiveComment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/CreateLiveComment', request, metadata || {}, this.methodDescriptorCreateLiveComment);
    };
    LivesV1Client.prototype.likeLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/LikeLive', request, metadata || {}, this.methodDescriptorLikeLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/LikeLive', request, metadata || {}, this.methodDescriptorLikeLive);
    };
    LivesV1Client.prototype.superLikeLive = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/SuperLikeLive', request, metadata || {}, this.methodDescriptorSuperLikeLive, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/SuperLikeLive', request, metadata || {}, this.methodDescriptorSuperLikeLive);
    };
    LivesV1Client.prototype.getSuperLikeLiveTiming = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetSuperLikeLiveTiming', request, metadata || {}, this.methodDescriptorGetSuperLikeLiveTiming, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetSuperLikeLiveTiming', request, metadata || {}, this.methodDescriptorGetSuperLikeLiveTiming);
    };
    LivesV1Client.prototype.listLiveViewingSessions = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListLiveViewingSessions', request, metadata || {}, this.methodDescriptorListLiveViewingSessions, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListLiveViewingSessions', request, metadata || {}, this.methodDescriptorListLiveViewingSessions);
    };
    LivesV1Client.prototype.listLiveHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListLiveHistories', request, metadata || {}, this.methodDescriptorListLiveHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListLiveHistories', request, metadata || {}, this.methodDescriptorListLiveHistories);
    };
    LivesV1Client.prototype.startSing = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/StartSing', request, metadata || {}, this.methodDescriptorStartSing, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/StartSing', request, metadata || {}, this.methodDescriptorStartSing);
    };
    LivesV1Client.prototype.finishSing = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/FinishSing', request, metadata || {}, this.methodDescriptorFinishSing, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/FinishSing', request, metadata || {}, this.methodDescriptorFinishSing);
    };
    LivesV1Client.prototype.requestSing = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/RequestSing', request, metadata || {}, this.methodDescriptorRequestSing, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/RequestSing', request, metadata || {}, this.methodDescriptorRequestSing);
    };
    LivesV1Client.prototype.listGiftTabs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListGiftTabs', request, metadata || {}, this.methodDescriptorListGiftTabs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListGiftTabs', request, metadata || {}, this.methodDescriptorListGiftTabs);
    };
    LivesV1Client.prototype.listGifts = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListGifts', request, metadata || {}, this.methodDescriptorListGifts, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListGifts', request, metadata || {}, this.methodDescriptorListGifts);
    };
    LivesV1Client.prototype.listGiftsForDownload = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListGiftsForDownload', request, metadata || {}, this.methodDescriptorListGiftsForDownload, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListGiftsForDownload', request, metadata || {}, this.methodDescriptorListGiftsForDownload);
    };
    LivesV1Client.prototype.listClosingTimes = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListClosingTimes', request, metadata || {}, this.methodDescriptorListClosingTimes, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListClosingTimes', request, metadata || {}, this.methodDescriptorListClosingTimes);
    };
    LivesV1Client.prototype.listInitialClosingTimes = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListInitialClosingTimes', request, metadata || {}, this.methodDescriptorListInitialClosingTimes, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListInitialClosingTimes', request, metadata || {}, this.methodDescriptorListInitialClosingTimes);
    };
    LivesV1Client.prototype.sendGift = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/SendGift', request, metadata || {}, this.methodDescriptorSendGift, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/SendGift', request, metadata || {}, this.methodDescriptorSendGift);
    };
    LivesV1Client.prototype.listFanInfoFaveBadgeTabs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListFanInfoFaveBadgeTabs', request, metadata || {}, this.methodDescriptorListFanInfoFaveBadgeTabs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListFanInfoFaveBadgeTabs', request, metadata || {}, this.methodDescriptorListFanInfoFaveBadgeTabs);
    };
    LivesV1Client.prototype.listFanInfoSongFaveTabs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListFanInfoSongFaveTabs', request, metadata || {}, this.methodDescriptorListFanInfoSongFaveTabs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListFanInfoSongFaveTabs', request, metadata || {}, this.methodDescriptorListFanInfoSongFaveTabs);
    };
    LivesV1Client.prototype.getMonthlyLiveListenerInfo = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetMonthlyLiveListenerInfo', request, metadata || {}, this.methodDescriptorGetMonthlyLiveListenerInfo, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetMonthlyLiveListenerInfo', request, metadata || {}, this.methodDescriptorGetMonthlyLiveListenerInfo);
    };
    LivesV1Client.prototype.listPfFavePointsRankingListeners = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListPfFavePointsRankingListeners', request, metadata || {}, this.methodDescriptorListPfFavePointsRankingListeners, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListPfFavePointsRankingListeners', request, metadata || {}, this.methodDescriptorListPfFavePointsRankingListeners);
    };
    LivesV1Client.prototype.getLiveResultBasicInfo = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetLiveResultBasicInfo', request, metadata || {}, this.methodDescriptorGetLiveResultBasicInfo, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetLiveResultBasicInfo', request, metadata || {}, this.methodDescriptorGetLiveResultBasicInfo);
    };
    LivesV1Client.prototype.getLiveResultGift = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetLiveResultGift', request, metadata || {}, this.methodDescriptorGetLiveResultGift, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetLiveResultGift', request, metadata || {}, this.methodDescriptorGetLiveResultGift);
    };
    LivesV1Client.prototype.getUserDashboardInfo = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetUserDashboardInfo', request, metadata || {}, this.methodDescriptorGetUserDashboardInfo, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetUserDashboardInfo', request, metadata || {}, this.methodDescriptorGetUserDashboardInfo);
    };
    LivesV1Client.prototype.shareLiveToTwitter = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ShareLiveToTwitter', request, metadata || {}, this.methodDescriptorShareLiveToTwitter, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ShareLiveToTwitter', request, metadata || {}, this.methodDescriptorShareLiveToTwitter);
    };
    LivesV1Client.prototype.getLivePermissionV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetLivePermissionV1', request, metadata || {}, this.methodDescriptorGetLivePermissionV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetLivePermissionV1', request, metadata || {}, this.methodDescriptorGetLivePermissionV1);
    };
    LivesV1Client.prototype.getLiveAvailableTimeRangeV1 = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetLiveAvailableTimeRangeV1', request, metadata || {}, this.methodDescriptorGetLiveAvailableTimeRangeV1, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetLiveAvailableTimeRangeV1', request, metadata || {}, this.methodDescriptorGetLiveAvailableTimeRangeV1);
    };
    LivesV1Client.prototype.banListenerComment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/BanListenerComment', request, metadata || {}, this.methodDescriptorBanListenerComment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/BanListenerComment', request, metadata || {}, this.methodDescriptorBanListenerComment);
    };
    LivesV1Client.prototype.unbanListenerComment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UnbanListenerComment', request, metadata || {}, this.methodDescriptorUnbanListenerComment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UnbanListenerComment', request, metadata || {}, this.methodDescriptorUnbanListenerComment);
    };
    LivesV1Client.prototype.getBannedListenerComment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetBannedListenerComment', request, metadata || {}, this.methodDescriptorGetBannedListenerComment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetBannedListenerComment', request, metadata || {}, this.methodDescriptorGetBannedListenerComment);
    };
    LivesV1Client.prototype.banListenerReqCapture = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/BanListenerReqCapture', request, metadata || {}, this.methodDescriptorBanListenerReqCapture, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/BanListenerReqCapture', request, metadata || {}, this.methodDescriptorBanListenerReqCapture);
    };
    LivesV1Client.prototype.unbanListenerReqCapture = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UnbanListenerReqCapture', request, metadata || {}, this.methodDescriptorUnbanListenerReqCapture, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UnbanListenerReqCapture', request, metadata || {}, this.methodDescriptorUnbanListenerReqCapture);
    };
    LivesV1Client.prototype.getBannedListenerReqCapture = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetBannedListenerReqCapture', request, metadata || {}, this.methodDescriptorGetBannedListenerReqCapture, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetBannedListenerReqCapture', request, metadata || {}, this.methodDescriptorGetBannedListenerReqCapture);
    };
    LivesV1Client.prototype.getNextFavePointsProgress = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetNextFavePointsProgress', request, metadata || {}, this.methodDescriptorGetNextFavePointsProgress, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetNextFavePointsProgress', request, metadata || {}, this.methodDescriptorGetNextFavePointsProgress);
    };
    LivesV1Client.prototype.listLiveTimelineBanners = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListLiveTimelineBanners', request, metadata || {}, this.methodDescriptorListLiveTimelineBanners, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListLiveTimelineBanners', request, metadata || {}, this.methodDescriptorListLiveTimelineBanners);
    };
    LivesV1Client.prototype.getRankOfRankingEvent = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/GetRankOfRankingEvent', request, metadata || {}, this.methodDescriptorGetRankOfRankingEvent, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/GetRankOfRankingEvent', request, metadata || {}, this.methodDescriptorGetRankOfRankingEvent);
    };
    LivesV1Client.prototype.listRankingsOfRankingEvent = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/ListRankingsOfRankingEvent', request, metadata || {}, this.methodDescriptorListRankingsOfRankingEvent, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/ListRankingsOfRankingEvent', request, metadata || {}, this.methodDescriptorListRankingsOfRankingEvent);
    };
    LivesV1Client.prototype.sendHeartfulMention = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/SendHeartfulMention', request, metadata || {}, this.methodDescriptorSendHeartfulMention, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/SendHeartfulMention', request, metadata || {}, this.methodDescriptorSendHeartfulMention);
    };
    LivesV1Client.prototype.playYoutube = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/PlayYoutube', request, metadata || {}, this.methodDescriptorPlayYoutube, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/PlayYoutube', request, metadata || {}, this.methodDescriptorPlayYoutube);
    };
    LivesV1Client.prototype.pickUpSongRandomly = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/PickUpSongRandomly', request, metadata || {}, this.methodDescriptorPickUpSongRandomly, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/PickUpSongRandomly', request, metadata || {}, this.methodDescriptorPickUpSongRandomly);
    };
    LivesV1Client.prototype.banListenerCommentByModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/BanListenerCommentByModerator', request, metadata || {}, this.methodDescriptorBanListenerCommentByModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/BanListenerCommentByModerator', request, metadata || {}, this.methodDescriptorBanListenerCommentByModerator);
    };
    LivesV1Client.prototype.unbanListenerCommentByModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UnbanListenerCommentByModerator', request, metadata || {}, this.methodDescriptorUnbanListenerCommentByModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UnbanListenerCommentByModerator', request, metadata || {}, this.methodDescriptorUnbanListenerCommentByModerator);
    };
    LivesV1Client.prototype.banListenerReqCaptureByModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/BanListenerReqCaptureByModerator', request, metadata || {}, this.methodDescriptorBanListenerReqCaptureByModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/BanListenerReqCaptureByModerator', request, metadata || {}, this.methodDescriptorBanListenerReqCaptureByModerator);
    };
    LivesV1Client.prototype.unbanListenerReqCaptureByModerator = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.LivesV1/UnbanListenerReqCaptureByModerator', request, metadata || {}, this.methodDescriptorUnbanListenerReqCaptureByModerator, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.LivesV1/UnbanListenerReqCaptureByModerator', request, metadata || {}, this.methodDescriptorUnbanListenerReqCaptureByModerator);
    };
    return LivesV1Client;
}());
export { LivesV1Client };
