import { statusFromError, LocalizedMessage } from 'grpc-web-error-details';
/** gRPCのLocalizedMessageを取り出す関数
 * usage:
 * try {
 *  await grpcClient.someMethod()
 * } catch (e) {
 * const message = getGrpcLocalizedMessage(e)
 * }
 */
export var getLocalizedGrpcErrorMessage = function (error) {
    var _a = statusFromError(error), details = _a[1];
    if (!details)
        return null;
    for (var _i = 0, details_1 = details; _i < details_1.length; _i++) {
        var detail = details_1[_i];
        if (detail instanceof LocalizedMessage) {
            return detail.toObject().message;
        }
    }
    return null;
};
