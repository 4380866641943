var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request, GetTransactionInfoV1Request, ExecutePaymentV1Request } from '../singcolor-proto/ts/tsunagu_v1_pb';
import { TsunaguV1Client } from '../singcolor-proto/ts/Tsunagu_v1ServiceClientPb';
import { PearlAmount } from '../singcolor-proto/ts/pearls_entity_pb';
var TsunaguGrpcClient = /** @class */ (function () {
    function TsunaguGrpcClient(metadataBuilder) {
        this.metadataBuilder = metadataBuilder;
        this.client = new TsunaguV1Client(process.env.NEXT_PUBLIC_API_URL, null, null);
    }
    TsunaguGrpcClient.prototype.callGrpc = function (invoke) {
        return __awaiter(this, void 0, void 0, function () {
            var metadata, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.metadataBuilder()];
                    case 1:
                        metadata = _a.sent();
                        return [4 /*yield*/, invoke({ metadata: metadata })];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.toObject()];
                }
            });
        });
    };
    TsunaguGrpcClient.prototype.checkAbilityToPayTsunaguByDiamondsAndPearls = function (_a) {
        var price = _a.price;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new CheckAbilityToPayTsunaguByDiamondsAndPearlsV1Request();
                request.setPrice(price);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.checkAbilityToPayTsunaguByDiamondsAndPearlsV1(request, metadata);
                    })];
            });
        });
    };
    TsunaguGrpcClient.prototype.getTransactionInfoRequest = function (_a) {
        var paymentId = _a.paymentId;
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_b) {
                request = new GetTransactionInfoV1Request();
                request.setPaymentId(paymentId);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.getTransactionInfoV1(request, metadata);
                    })];
            });
        });
    };
    TsunaguGrpcClient.prototype.executePayment = function (_a) {
        var paymentId = _a.paymentId, pearlAmount = _a.pearlAmount, normalDiamondAmount = _a.normalDiamondAmount;
        return __awaiter(this, void 0, void 0, function () {
            var requestPearlAmount, request;
            var _this = this;
            return __generator(this, function (_b) {
                requestPearlAmount = new PearlAmount();
                requestPearlAmount.setRedAmount(pearlAmount.redAmount);
                requestPearlAmount.setBlueAmount(pearlAmount.blueAmount);
                requestPearlAmount.setRainbowAmount(pearlAmount.rainbowAmount);
                request = new ExecutePaymentV1Request();
                request.setPaymentId(paymentId);
                request.setPearlAmount(requestPearlAmount);
                request.setNormalDiamondAmount(normalDiamondAmount);
                return [2 /*return*/, this.callGrpc(function (_a) {
                        var metadata = _a.metadata;
                        return _this.client.executePaymentV1(request, metadata);
                    })];
            });
        });
    };
    return TsunaguGrpcClient;
}());
export { TsunaguGrpcClient };
