/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as organization_v1_pb from './organization_v1_pb';
var OrganizationV1Client = /** @class */ (function () {
    function OrganizationV1Client(hostname, credentials, options) {
        this.methodDescriptorGetOrganization = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/GetOrganization', grpcWeb.MethodType.UNARY, organization_v1_pb.GetOrganizationV1Request, organization_v1_pb.GetOrganizationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.GetOrganizationV1Response.deserializeBinary);
        this.methodDescriptorCreateOrganization = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/CreateOrganization', grpcWeb.MethodType.UNARY, organization_v1_pb.CreateOrganizationV1Request, organization_v1_pb.CreateOrganizationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.CreateOrganizationV1Response.deserializeBinary);
        this.methodDescriptorUpdateOrganization = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/UpdateOrganization', grpcWeb.MethodType.UNARY, organization_v1_pb.UpdateOrganizationV1Request, organization_v1_pb.UpdateOrganizationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.UpdateOrganizationV1Response.deserializeBinary);
        this.methodDescriptorListSupportPlans = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListSupportPlans', grpcWeb.MethodType.UNARY, organization_v1_pb.ListSupportPlansV1Request, organization_v1_pb.ListSupportPlansV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListSupportPlansV1Response.deserializeBinary);
        this.methodDescriptorCreateSupportPlan = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/CreateSupportPlan', grpcWeb.MethodType.UNARY, organization_v1_pb.CreateSupportPlanV1Request, organization_v1_pb.CreateSupportPlanV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.CreateSupportPlanV1Response.deserializeBinary);
        this.methodDescriptorUpdateSupportPlan = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/UpdateSupportPlan', grpcWeb.MethodType.UNARY, organization_v1_pb.UpdateSupportPlanV1Request, organization_v1_pb.UpdateSupportPlanV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.UpdateSupportPlanV1Response.deserializeBinary);
        this.methodDescriptorDeleteSupportPlan = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/DeleteSupportPlan', grpcWeb.MethodType.UNARY, organization_v1_pb.DeleteSupportPlanV1Request, organization_v1_pb.DeleteSupportPlanV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.DeleteSupportPlanV1Response.deserializeBinary);
        this.methodDescriptorListSupportPlanCreationHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListSupportPlanCreationHistories', grpcWeb.MethodType.UNARY, organization_v1_pb.ListSupportPlanCreationHistoriesV1Request, organization_v1_pb.ListSupportPlanCreationHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListSupportPlanCreationHistoriesV1Response.deserializeBinary);
        this.methodDescriptorListSupportPlanUpdateHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListSupportPlanUpdateHistories', grpcWeb.MethodType.UNARY, organization_v1_pb.ListSupportPlanUpdateHistoriesV1Request, organization_v1_pb.ListSupportPlanUpdateHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListSupportPlanUpdateHistoriesV1Response.deserializeBinary);
        this.methodDescriptorListSupportPlanTags = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListSupportPlanTags', grpcWeb.MethodType.UNARY, organization_v1_pb.ListSupportPlanTagsV1Request, organization_v1_pb.ListSupportPlanTagsV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListSupportPlanTagsV1Response.deserializeBinary);
        this.methodDescriptorGetOrganizationStaff = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/GetOrganizationStaff', grpcWeb.MethodType.UNARY, organization_v1_pb.GetOrganizationStaffV1Request, organization_v1_pb.GetOrganizationStaffV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.GetOrganizationStaffV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationStaffs = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationStaffs', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationStaffsV1Request, organization_v1_pb.ListOrganizationStaffsV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationStaffsV1Response.deserializeBinary);
        this.methodDescriptorUpdateOrganizationStaffRole = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/UpdateOrganizationStaffRole', grpcWeb.MethodType.UNARY, organization_v1_pb.UpdateOrganizationStaffRoleV1Request, organization_v1_pb.UpdateOrganizationStaffRoleV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.UpdateOrganizationStaffRoleV1Response.deserializeBinary);
        this.methodDescriptorDeleteOrganizationStaff = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/DeleteOrganizationStaff', grpcWeb.MethodType.UNARY, organization_v1_pb.DeleteOrganizationStaffV1Request, organization_v1_pb.DeleteOrganizationStaffV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.DeleteOrganizationStaffV1Response.deserializeBinary);
        this.methodDescriptorMyOrganizationApplicationInfo = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/MyOrganizationApplicationInfo', grpcWeb.MethodType.UNARY, organization_v1_pb.MyOrganizationApplicationInfoV1Request, organization_v1_pb.MyOrganizationApplicationInfoV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.MyOrganizationApplicationInfoV1Response.deserializeBinary);
        this.methodDescriptorCreateOrganizationStaffApplication = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/CreateOrganizationStaffApplication', grpcWeb.MethodType.UNARY, organization_v1_pb.CreateOrganizationStaffApplicationV1Request, organization_v1_pb.CreateOrganizationStaffApplicationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.CreateOrganizationStaffApplicationV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationStaffApplications = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationStaffApplications', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationStaffApplicationsV1Request, organization_v1_pb.ListOrganizationStaffApplicationsV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationStaffApplicationsV1Response.deserializeBinary);
        this.methodDescriptorApproveOrganizationStaffApplication = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ApproveOrganizationStaffApplication', grpcWeb.MethodType.UNARY, organization_v1_pb.ApproveOrganizationStaffApplicationV1Request, organization_v1_pb.ApproveOrganizationStaffApplicationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ApproveOrganizationStaffApplicationV1Response.deserializeBinary);
        this.methodDescriptorRejectOrganizationStaffApplication = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/RejectOrganizationStaffApplication', grpcWeb.MethodType.UNARY, organization_v1_pb.RejectOrganizationStaffApplicationV1Request, organization_v1_pb.RejectOrganizationStaffApplicationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.RejectOrganizationStaffApplicationV1Response.deserializeBinary);
        this.methodDescriptorDeleteOrganizationStaffApplication = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/DeleteOrganizationStaffApplication', grpcWeb.MethodType.UNARY, organization_v1_pb.DeleteOrganizationStaffApplicationV1Request, organization_v1_pb.DeleteOrganizationStaffApplicationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.DeleteOrganizationStaffApplicationV1Response.deserializeBinary);
        this.methodDescriptorChangeOrganizationOwner = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ChangeOrganizationOwner', grpcWeb.MethodType.UNARY, organization_v1_pb.ChangeOrganizationOwnerV1Request, organization_v1_pb.ChangeOrganizationOwnerV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ChangeOrganizationOwnerV1Response.deserializeBinary);
        this.methodDescriptorGetOrganizationLiver = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/GetOrganizationLiver', grpcWeb.MethodType.UNARY, organization_v1_pb.GetOrganizationLiverV1Request, organization_v1_pb.GetOrganizationLiverV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.GetOrganizationLiverV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationLivers = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationLivers', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationLiversV1Request, organization_v1_pb.ListOrganizationLiversV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationLiversV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationLiverDetails = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationLiverDetails', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationLiverDetailsV1Request, organization_v1_pb.ListOrganizationLiverDetailsV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationLiverDetailsV1Response.deserializeBinary);
        this.methodDescriptorInvalidateOrganizationLiverDetailsCache = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/InvalidateOrganizationLiverDetailsCache', grpcWeb.MethodType.UNARY, organization_v1_pb.InvalidateOrganizationLiverDetailsCacheV1Request, organization_v1_pb.InvalidateOrganizationLiverDetailsCacheV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.InvalidateOrganizationLiverDetailsCacheV1Response.deserializeBinary);
        this.methodDescriptorDeleteOrganizationLiver = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/DeleteOrganizationLiver', grpcWeb.MethodType.UNARY, organization_v1_pb.DeleteOrganizationLiverV1Request, organization_v1_pb.DeleteOrganizationLiverV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.DeleteOrganizationLiverV1Response.deserializeBinary);
        this.methodDescriptorCreateOrganizationLiverApplication = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/CreateOrganizationLiverApplication', grpcWeb.MethodType.UNARY, organization_v1_pb.CreateOrganizationLiverApplicationV1Request, organization_v1_pb.CreateOrganizationLiverApplicationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.CreateOrganizationLiverApplicationV1Response.deserializeBinary);
        this.methodDescriptorDeleteOrganizationLiverApplication = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/DeleteOrganizationLiverApplication', grpcWeb.MethodType.UNARY, organization_v1_pb.DeleteOrganizationLiverApplicationV1Request, organization_v1_pb.DeleteOrganizationLiverApplicationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.DeleteOrganizationLiverApplicationV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationLiverApplications = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationLiverApplications', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationLiverApplicationsV1Request, organization_v1_pb.ListOrganizationLiverApplicationsV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationLiverApplicationsV1Response.deserializeBinary);
        this.methodDescriptorApproveOrganizationLiverApplication = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ApproveOrganizationLiverApplication', grpcWeb.MethodType.UNARY, organization_v1_pb.ApproveOrganizationLiverApplicationV1Request, organization_v1_pb.ApproveOrganizationLiverApplicationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ApproveOrganizationLiverApplicationV1Response.deserializeBinary);
        this.methodDescriptorRejectOrganizationLiverApplication = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/RejectOrganizationLiverApplication', grpcWeb.MethodType.UNARY, organization_v1_pb.RejectOrganizationLiverApplicationV1Request, organization_v1_pb.RejectOrganizationLiverApplicationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.RejectOrganizationLiverApplicationV1Response.deserializeBinary);
        this.methodDescriptorSearchOrganization = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/SearchOrganization', grpcWeb.MethodType.UNARY, organization_v1_pb.SearchOrganizationV1Request, organization_v1_pb.SearchOrganizationV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.SearchOrganizationV1Response.deserializeBinary);
        this.methodDescriptorListRedashDashboards = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListRedashDashboards', grpcWeb.MethodType.UNARY, organization_v1_pb.ListRedashDashboardsV1Request, organization_v1_pb.ListRedashDashboardsV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListRedashDashboardsV1Response.deserializeBinary);
        this.methodDescriptorListRedashExportQueryTypes = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListRedashExportQueryTypes', grpcWeb.MethodType.UNARY, organization_v1_pb.ListRedashExportQueryTypesV1Request, organization_v1_pb.ListRedashExportQueryTypesV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListRedashExportQueryTypesV1Response.deserializeBinary);
        this.methodDescriptorListRedashExportHistoriesByQueryType = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListRedashExportHistoriesByQueryType', grpcWeb.MethodType.UNARY, organization_v1_pb.ListRedashExportHistoriesByQueryTypeV1Request, organization_v1_pb.ListRedashExportHistoriesByQueryTypeV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListRedashExportHistoriesByQueryTypeV1Response.deserializeBinary);
        this.methodDescriptorGenerateRedashExportPresignedUrl = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/GenerateRedashExportPresignedUrl', grpcWeb.MethodType.UNARY, organization_v1_pb.GenerateRedashExportPresignedUrlV1Request, organization_v1_pb.GenerateRedashExportPresignedUrlV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.GenerateRedashExportPresignedUrlV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationUpdateHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationUpdateHistories', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationUpdateHistoriesV1Request, organization_v1_pb.ListOrganizationUpdateHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationUpdateHistoriesV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationStaffRoleUpdateHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationStaffRoleUpdateHistories', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationStaffRoleUpdateHistoriesV1Request, organization_v1_pb.ListOrganizationStaffRoleUpdateHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationStaffRoleUpdateHistoriesV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationLiverLeaveHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationLiverLeaveHistories', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationLiverLeaveHistoriesV1Request, organization_v1_pb.ListOrganizationLiverLeaveHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationLiverLeaveHistoriesV1Response.deserializeBinary);
        this.methodDescriptorCreateOrganizationLiverGroup = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/CreateOrganizationLiverGroup', grpcWeb.MethodType.UNARY, organization_v1_pb.CreateOrganizationLiverGroupV1Request, organization_v1_pb.CreateOrganizationLiverGroupV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.CreateOrganizationLiverGroupV1Response.deserializeBinary);
        this.methodDescriptorUpdateOrganizationLiverGroup = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/UpdateOrganizationLiverGroup', grpcWeb.MethodType.UNARY, organization_v1_pb.UpdateOrganizationLiverGroupV1Request, organization_v1_pb.UpdateOrganizationLiverGroupV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.UpdateOrganizationLiverGroupV1Response.deserializeBinary);
        this.methodDescriptorDeleteOrganizationLiverGroup = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/DeleteOrganizationLiverGroup', grpcWeb.MethodType.UNARY, organization_v1_pb.DeleteOrganizationLiverGroupV1Request, organization_v1_pb.DeleteOrganizationLiverGroupV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.DeleteOrganizationLiverGroupV1Response.deserializeBinary);
        this.methodDescriptorGetOrganizationLiverGroup = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/GetOrganizationLiverGroup', grpcWeb.MethodType.UNARY, organization_v1_pb.GetOrganizationLiverGroupV1Request, organization_v1_pb.GetOrganizationLiverGroupV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.GetOrganizationLiverGroupV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationLiverGroups = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationLiverGroups', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationLiverGroupsV1Request, organization_v1_pb.ListOrganizationLiverGroupsV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationLiverGroupsV1Response.deserializeBinary);
        this.methodDescriptorCreateOrganizationLiverGroupLiver = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/CreateOrganizationLiverGroupLiver', grpcWeb.MethodType.UNARY, organization_v1_pb.CreateOrganizationLiverGroupLiversV1Request, organization_v1_pb.CreateOrganizationLiverGroupLiversV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.CreateOrganizationLiverGroupLiversV1Response.deserializeBinary);
        this.methodDescriptorDeleteOrganizationLiverGroupLiver = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/DeleteOrganizationLiverGroupLiver', grpcWeb.MethodType.UNARY, organization_v1_pb.DeleteOrganizationLiverGroupLiverV1Request, organization_v1_pb.DeleteOrganizationLiverGroupLiverV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.DeleteOrganizationLiverGroupLiverV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationLiverGroupLivers = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationLiverGroupLivers', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationLiverGroupLiversV1Request, organization_v1_pb.ListOrganizationLiverGroupLiversV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationLiverGroupLiversV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationMonthlyDiamondHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationMonthlyDiamondHistories', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationMonthlyDiamondHistoriesV1Request, organization_v1_pb.ListOrganizationMonthlyDiamondHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationMonthlyDiamondHistoriesV1Response.deserializeBinary);
        this.methodDescriptorListOrganizationLiverMonthlyDiamondHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/ListOrganizationLiverMonthlyDiamondHistories', grpcWeb.MethodType.UNARY, organization_v1_pb.ListOrganizationLiverMonthlyDiamondHistoriesV1Request, organization_v1_pb.ListOrganizationLiverMonthlyDiamondHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.ListOrganizationLiverMonthlyDiamondHistoriesV1Response.deserializeBinary);
        this.methodDescriptorGenerateOrganizationMonthlyDiamondHistoryCSV = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/GenerateOrganizationMonthlyDiamondHistoryCSV', grpcWeb.MethodType.UNARY, organization_v1_pb.GenerateOrganizationMonthlyDiamondHistoryCSVV1Request, organization_v1_pb.GenerateOrganizationMonthlyDiamondHistoryCSVV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.GenerateOrganizationMonthlyDiamondHistoryCSVV1Response.deserializeBinary);
        this.methodDescriptorGenerateOrganizationLiverMonthlyDiamondHistoryCSV = new grpcWeb.MethodDescriptor('/jp.singcolor.OrganizationV1/GenerateOrganizationLiverMonthlyDiamondHistoryCSV', grpcWeb.MethodType.UNARY, organization_v1_pb.GenerateOrganizationLiverMonthlyDiamondHistoryCSVV1Request, organization_v1_pb.GenerateOrganizationLiverMonthlyDiamondHistoryCSVV1Response, function (request) {
            return request.serializeBinary();
        }, organization_v1_pb.GenerateOrganizationLiverMonthlyDiamondHistoryCSVV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    OrganizationV1Client.prototype.getOrganization = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/GetOrganization', request, metadata || {}, this.methodDescriptorGetOrganization, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/GetOrganization', request, metadata || {}, this.methodDescriptorGetOrganization);
    };
    OrganizationV1Client.prototype.createOrganization = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/CreateOrganization', request, metadata || {}, this.methodDescriptorCreateOrganization, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/CreateOrganization', request, metadata || {}, this.methodDescriptorCreateOrganization);
    };
    OrganizationV1Client.prototype.updateOrganization = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/UpdateOrganization', request, metadata || {}, this.methodDescriptorUpdateOrganization, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/UpdateOrganization', request, metadata || {}, this.methodDescriptorUpdateOrganization);
    };
    OrganizationV1Client.prototype.listSupportPlans = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListSupportPlans', request, metadata || {}, this.methodDescriptorListSupportPlans, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListSupportPlans', request, metadata || {}, this.methodDescriptorListSupportPlans);
    };
    OrganizationV1Client.prototype.createSupportPlan = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/CreateSupportPlan', request, metadata || {}, this.methodDescriptorCreateSupportPlan, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/CreateSupportPlan', request, metadata || {}, this.methodDescriptorCreateSupportPlan);
    };
    OrganizationV1Client.prototype.updateSupportPlan = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/UpdateSupportPlan', request, metadata || {}, this.methodDescriptorUpdateSupportPlan, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/UpdateSupportPlan', request, metadata || {}, this.methodDescriptorUpdateSupportPlan);
    };
    OrganizationV1Client.prototype.deleteSupportPlan = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/DeleteSupportPlan', request, metadata || {}, this.methodDescriptorDeleteSupportPlan, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/DeleteSupportPlan', request, metadata || {}, this.methodDescriptorDeleteSupportPlan);
    };
    OrganizationV1Client.prototype.listSupportPlanCreationHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListSupportPlanCreationHistories', request, metadata || {}, this.methodDescriptorListSupportPlanCreationHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListSupportPlanCreationHistories', request, metadata || {}, this.methodDescriptorListSupportPlanCreationHistories);
    };
    OrganizationV1Client.prototype.listSupportPlanUpdateHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListSupportPlanUpdateHistories', request, metadata || {}, this.methodDescriptorListSupportPlanUpdateHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListSupportPlanUpdateHistories', request, metadata || {}, this.methodDescriptorListSupportPlanUpdateHistories);
    };
    OrganizationV1Client.prototype.listSupportPlanTags = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListSupportPlanTags', request, metadata || {}, this.methodDescriptorListSupportPlanTags, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListSupportPlanTags', request, metadata || {}, this.methodDescriptorListSupportPlanTags);
    };
    OrganizationV1Client.prototype.getOrganizationStaff = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/GetOrganizationStaff', request, metadata || {}, this.methodDescriptorGetOrganizationStaff, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/GetOrganizationStaff', request, metadata || {}, this.methodDescriptorGetOrganizationStaff);
    };
    OrganizationV1Client.prototype.listOrganizationStaffs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationStaffs', request, metadata || {}, this.methodDescriptorListOrganizationStaffs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationStaffs', request, metadata || {}, this.methodDescriptorListOrganizationStaffs);
    };
    OrganizationV1Client.prototype.updateOrganizationStaffRole = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/UpdateOrganizationStaffRole', request, metadata || {}, this.methodDescriptorUpdateOrganizationStaffRole, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/UpdateOrganizationStaffRole', request, metadata || {}, this.methodDescriptorUpdateOrganizationStaffRole);
    };
    OrganizationV1Client.prototype.deleteOrganizationStaff = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/DeleteOrganizationStaff', request, metadata || {}, this.methodDescriptorDeleteOrganizationStaff, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/DeleteOrganizationStaff', request, metadata || {}, this.methodDescriptorDeleteOrganizationStaff);
    };
    OrganizationV1Client.prototype.myOrganizationApplicationInfo = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/MyOrganizationApplicationInfo', request, metadata || {}, this.methodDescriptorMyOrganizationApplicationInfo, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/MyOrganizationApplicationInfo', request, metadata || {}, this.methodDescriptorMyOrganizationApplicationInfo);
    };
    OrganizationV1Client.prototype.createOrganizationStaffApplication = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/CreateOrganizationStaffApplication', request, metadata || {}, this.methodDescriptorCreateOrganizationStaffApplication, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/CreateOrganizationStaffApplication', request, metadata || {}, this.methodDescriptorCreateOrganizationStaffApplication);
    };
    OrganizationV1Client.prototype.listOrganizationStaffApplications = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationStaffApplications', request, metadata || {}, this.methodDescriptorListOrganizationStaffApplications, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationStaffApplications', request, metadata || {}, this.methodDescriptorListOrganizationStaffApplications);
    };
    OrganizationV1Client.prototype.approveOrganizationStaffApplication = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ApproveOrganizationStaffApplication', request, metadata || {}, this.methodDescriptorApproveOrganizationStaffApplication, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ApproveOrganizationStaffApplication', request, metadata || {}, this.methodDescriptorApproveOrganizationStaffApplication);
    };
    OrganizationV1Client.prototype.rejectOrganizationStaffApplication = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/RejectOrganizationStaffApplication', request, metadata || {}, this.methodDescriptorRejectOrganizationStaffApplication, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/RejectOrganizationStaffApplication', request, metadata || {}, this.methodDescriptorRejectOrganizationStaffApplication);
    };
    OrganizationV1Client.prototype.deleteOrganizationStaffApplication = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/DeleteOrganizationStaffApplication', request, metadata || {}, this.methodDescriptorDeleteOrganizationStaffApplication, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/DeleteOrganizationStaffApplication', request, metadata || {}, this.methodDescriptorDeleteOrganizationStaffApplication);
    };
    OrganizationV1Client.prototype.changeOrganizationOwner = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ChangeOrganizationOwner', request, metadata || {}, this.methodDescriptorChangeOrganizationOwner, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ChangeOrganizationOwner', request, metadata || {}, this.methodDescriptorChangeOrganizationOwner);
    };
    OrganizationV1Client.prototype.getOrganizationLiver = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/GetOrganizationLiver', request, metadata || {}, this.methodDescriptorGetOrganizationLiver, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/GetOrganizationLiver', request, metadata || {}, this.methodDescriptorGetOrganizationLiver);
    };
    OrganizationV1Client.prototype.listOrganizationLivers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationLivers', request, metadata || {}, this.methodDescriptorListOrganizationLivers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationLivers', request, metadata || {}, this.methodDescriptorListOrganizationLivers);
    };
    OrganizationV1Client.prototype.listOrganizationLiverDetails = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationLiverDetails', request, metadata || {}, this.methodDescriptorListOrganizationLiverDetails, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationLiverDetails', request, metadata || {}, this.methodDescriptorListOrganizationLiverDetails);
    };
    OrganizationV1Client.prototype.invalidateOrganizationLiverDetailsCache = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/InvalidateOrganizationLiverDetailsCache', request, metadata || {}, this.methodDescriptorInvalidateOrganizationLiverDetailsCache, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/InvalidateOrganizationLiverDetailsCache', request, metadata || {}, this.methodDescriptorInvalidateOrganizationLiverDetailsCache);
    };
    OrganizationV1Client.prototype.deleteOrganizationLiver = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/DeleteOrganizationLiver', request, metadata || {}, this.methodDescriptorDeleteOrganizationLiver, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/DeleteOrganizationLiver', request, metadata || {}, this.methodDescriptorDeleteOrganizationLiver);
    };
    OrganizationV1Client.prototype.createOrganizationLiverApplication = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/CreateOrganizationLiverApplication', request, metadata || {}, this.methodDescriptorCreateOrganizationLiverApplication, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/CreateOrganizationLiverApplication', request, metadata || {}, this.methodDescriptorCreateOrganizationLiverApplication);
    };
    OrganizationV1Client.prototype.deleteOrganizationLiverApplication = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/DeleteOrganizationLiverApplication', request, metadata || {}, this.methodDescriptorDeleteOrganizationLiverApplication, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/DeleteOrganizationLiverApplication', request, metadata || {}, this.methodDescriptorDeleteOrganizationLiverApplication);
    };
    OrganizationV1Client.prototype.listOrganizationLiverApplications = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationLiverApplications', request, metadata || {}, this.methodDescriptorListOrganizationLiverApplications, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationLiverApplications', request, metadata || {}, this.methodDescriptorListOrganizationLiverApplications);
    };
    OrganizationV1Client.prototype.approveOrganizationLiverApplication = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ApproveOrganizationLiverApplication', request, metadata || {}, this.methodDescriptorApproveOrganizationLiverApplication, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ApproveOrganizationLiverApplication', request, metadata || {}, this.methodDescriptorApproveOrganizationLiverApplication);
    };
    OrganizationV1Client.prototype.rejectOrganizationLiverApplication = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/RejectOrganizationLiverApplication', request, metadata || {}, this.methodDescriptorRejectOrganizationLiverApplication, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/RejectOrganizationLiverApplication', request, metadata || {}, this.methodDescriptorRejectOrganizationLiverApplication);
    };
    OrganizationV1Client.prototype.searchOrganization = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/SearchOrganization', request, metadata || {}, this.methodDescriptorSearchOrganization, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/SearchOrganization', request, metadata || {}, this.methodDescriptorSearchOrganization);
    };
    OrganizationV1Client.prototype.listRedashDashboards = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListRedashDashboards', request, metadata || {}, this.methodDescriptorListRedashDashboards, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListRedashDashboards', request, metadata || {}, this.methodDescriptorListRedashDashboards);
    };
    OrganizationV1Client.prototype.listRedashExportQueryTypes = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListRedashExportQueryTypes', request, metadata || {}, this.methodDescriptorListRedashExportQueryTypes, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListRedashExportQueryTypes', request, metadata || {}, this.methodDescriptorListRedashExportQueryTypes);
    };
    OrganizationV1Client.prototype.listRedashExportHistoriesByQueryType = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListRedashExportHistoriesByQueryType', request, metadata || {}, this.methodDescriptorListRedashExportHistoriesByQueryType, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListRedashExportHistoriesByQueryType', request, metadata || {}, this.methodDescriptorListRedashExportHistoriesByQueryType);
    };
    OrganizationV1Client.prototype.generateRedashExportPresignedUrl = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/GenerateRedashExportPresignedUrl', request, metadata || {}, this.methodDescriptorGenerateRedashExportPresignedUrl, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/GenerateRedashExportPresignedUrl', request, metadata || {}, this.methodDescriptorGenerateRedashExportPresignedUrl);
    };
    OrganizationV1Client.prototype.listOrganizationUpdateHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationUpdateHistories', request, metadata || {}, this.methodDescriptorListOrganizationUpdateHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationUpdateHistories', request, metadata || {}, this.methodDescriptorListOrganizationUpdateHistories);
    };
    OrganizationV1Client.prototype.listOrganizationStaffRoleUpdateHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationStaffRoleUpdateHistories', request, metadata || {}, this.methodDescriptorListOrganizationStaffRoleUpdateHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationStaffRoleUpdateHistories', request, metadata || {}, this.methodDescriptorListOrganizationStaffRoleUpdateHistories);
    };
    OrganizationV1Client.prototype.listOrganizationLiverLeaveHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationLiverLeaveHistories', request, metadata || {}, this.methodDescriptorListOrganizationLiverLeaveHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationLiverLeaveHistories', request, metadata || {}, this.methodDescriptorListOrganizationLiverLeaveHistories);
    };
    OrganizationV1Client.prototype.createOrganizationLiverGroup = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/CreateOrganizationLiverGroup', request, metadata || {}, this.methodDescriptorCreateOrganizationLiverGroup, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/CreateOrganizationLiverGroup', request, metadata || {}, this.methodDescriptorCreateOrganizationLiverGroup);
    };
    OrganizationV1Client.prototype.updateOrganizationLiverGroup = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/UpdateOrganizationLiverGroup', request, metadata || {}, this.methodDescriptorUpdateOrganizationLiverGroup, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/UpdateOrganizationLiverGroup', request, metadata || {}, this.methodDescriptorUpdateOrganizationLiverGroup);
    };
    OrganizationV1Client.prototype.deleteOrganizationLiverGroup = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/DeleteOrganizationLiverGroup', request, metadata || {}, this.methodDescriptorDeleteOrganizationLiverGroup, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/DeleteOrganizationLiverGroup', request, metadata || {}, this.methodDescriptorDeleteOrganizationLiverGroup);
    };
    OrganizationV1Client.prototype.getOrganizationLiverGroup = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/GetOrganizationLiverGroup', request, metadata || {}, this.methodDescriptorGetOrganizationLiverGroup, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/GetOrganizationLiverGroup', request, metadata || {}, this.methodDescriptorGetOrganizationLiverGroup);
    };
    OrganizationV1Client.prototype.listOrganizationLiverGroups = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationLiverGroups', request, metadata || {}, this.methodDescriptorListOrganizationLiverGroups, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationLiverGroups', request, metadata || {}, this.methodDescriptorListOrganizationLiverGroups);
    };
    OrganizationV1Client.prototype.createOrganizationLiverGroupLiver = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/CreateOrganizationLiverGroupLiver', request, metadata || {}, this.methodDescriptorCreateOrganizationLiverGroupLiver, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/CreateOrganizationLiverGroupLiver', request, metadata || {}, this.methodDescriptorCreateOrganizationLiverGroupLiver);
    };
    OrganizationV1Client.prototype.deleteOrganizationLiverGroupLiver = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/DeleteOrganizationLiverGroupLiver', request, metadata || {}, this.methodDescriptorDeleteOrganizationLiverGroupLiver, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/DeleteOrganizationLiverGroupLiver', request, metadata || {}, this.methodDescriptorDeleteOrganizationLiverGroupLiver);
    };
    OrganizationV1Client.prototype.listOrganizationLiverGroupLivers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationLiverGroupLivers', request, metadata || {}, this.methodDescriptorListOrganizationLiverGroupLivers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationLiverGroupLivers', request, metadata || {}, this.methodDescriptorListOrganizationLiverGroupLivers);
    };
    OrganizationV1Client.prototype.listOrganizationMonthlyDiamondHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationMonthlyDiamondHistories', request, metadata || {}, this.methodDescriptorListOrganizationMonthlyDiamondHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationMonthlyDiamondHistories', request, metadata || {}, this.methodDescriptorListOrganizationMonthlyDiamondHistories);
    };
    OrganizationV1Client.prototype.listOrganizationLiverMonthlyDiamondHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/ListOrganizationLiverMonthlyDiamondHistories', request, metadata || {}, this.methodDescriptorListOrganizationLiverMonthlyDiamondHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/ListOrganizationLiverMonthlyDiamondHistories', request, metadata || {}, this.methodDescriptorListOrganizationLiverMonthlyDiamondHistories);
    };
    OrganizationV1Client.prototype.generateOrganizationMonthlyDiamondHistoryCSV = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/GenerateOrganizationMonthlyDiamondHistoryCSV', request, metadata || {}, this.methodDescriptorGenerateOrganizationMonthlyDiamondHistoryCSV, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/GenerateOrganizationMonthlyDiamondHistoryCSV', request, metadata || {}, this.methodDescriptorGenerateOrganizationMonthlyDiamondHistoryCSV);
    };
    OrganizationV1Client.prototype.generateOrganizationLiverMonthlyDiamondHistoryCSV = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.OrganizationV1/GenerateOrganizationLiverMonthlyDiamondHistoryCSV', request, metadata || {}, this.methodDescriptorGenerateOrganizationLiverMonthlyDiamondHistoryCSV, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.OrganizationV1/GenerateOrganizationLiverMonthlyDiamondHistoryCSV', request, metadata || {}, this.methodDescriptorGenerateOrganizationLiverMonthlyDiamondHistoryCSV);
    };
    return OrganizationV1Client;
}());
export { OrganizationV1Client };
