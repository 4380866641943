/**
 * @fileoverview gRPC-Web generated client stub for jp.singcolor
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as pearls_v1_pb from './pearls_v1_pb';
var PearlsV1Client = /** @class */ (function () {
    function PearlsV1Client(hostname, credentials, options) {
        this.methodDescriptorGetPearlBalanceSummary = new grpcWeb.MethodDescriptor('/jp.singcolor.PearlsV1/GetPearlBalanceSummary', grpcWeb.MethodType.UNARY, pearls_v1_pb.GetPearlBalanceSummaryV1Request, pearls_v1_pb.GetPearlBalanceSummaryV1Response, function (request) {
            return request.serializeBinary();
        }, pearls_v1_pb.GetPearlBalanceSummaryV1Response.deserializeBinary);
        this.methodDescriptorListPearlBalanceInOutHistories = new grpcWeb.MethodDescriptor('/jp.singcolor.PearlsV1/ListPearlBalanceInOutHistories', grpcWeb.MethodType.UNARY, pearls_v1_pb.ListPearlBalanceInOutHistoriesV1Request, pearls_v1_pb.ListPearlBalanceInOutHistoriesV1Response, function (request) {
            return request.serializeBinary();
        }, pearls_v1_pb.ListPearlBalanceInOutHistoriesV1Response.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'binary';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    PearlsV1Client.prototype.getPearlBalanceSummary = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.PearlsV1/GetPearlBalanceSummary', request, metadata || {}, this.methodDescriptorGetPearlBalanceSummary, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.PearlsV1/GetPearlBalanceSummary', request, metadata || {}, this.methodDescriptorGetPearlBalanceSummary);
    };
    PearlsV1Client.prototype.listPearlBalanceInOutHistories = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/jp.singcolor.PearlsV1/ListPearlBalanceInOutHistories', request, metadata || {}, this.methodDescriptorListPearlBalanceInOutHistories, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/jp.singcolor.PearlsV1/ListPearlBalanceInOutHistories', request, metadata || {}, this.methodDescriptorListPearlBalanceInOutHistories);
    };
    return PearlsV1Client;
}());
export { PearlsV1Client };
